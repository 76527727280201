import * as d3 from "d3"

document.addEventListener('turbo:load', function() {
  if (document.querySelector('.my-runs-index')) {

    let element = document.getElementById('test');

    function getRunsData() {
      return new Promise(function(resolve, reject) {
        var request = new XMLHttpRequest();
        request.open('GET', '/my/runs/distance');
        request.setRequestHeader('accept', 'application/json');

        request.onload = function() {
          if ([200,304].includes(request.status)) {
            resolve(request.response);
          } else {
            reject(Error('The data didn\'t load successfully. Error code:' + request.statusText));
          }
        };

        request.onerror = function() {
          reject(Error('There was a network error.'));
        };

        request.send();
      });
    }

    getRunsData()
    .then(function(response) {
      cleanData(response);
    }, function(error) {
      console.log(error);
    })

    let data = [];

    function cleanData(response) {
      JSON.parse(response).forEach(function(d) {
        data.push({ date: d3.isoParse(d.date), value: parseFloat(d.distance) });
      });

      drawChart();
    }

    function drawChart() {
      var margin = {top: 20, right: 0, bottom: 30, left: 30},
          width = element.getBoundingClientRect().width,
          height = 350

      var y = d3.scaleLinear()
                .domain([0, d3.max(data, d => d.value)])
                .range([height - margin.bottom, margin.top])
                .nice()

      var x = d3.scaleBand()
                .domain(data.map(d => d.date))
                .rangeRound([margin.left, width - margin.right])
                .padding(0.1)

      const svg = d3.select(element).append("svg")
                    .attr("viewBox", [0, 0, width, height]);

      var xAxis = g => g
         .attr("transform", `translate(0,${height - margin.bottom})`)
         .call(d3.axisBottom(x).tickSizeOuter(0).tickFormat(d3.timeFormat("%m/%y")));

      var yAxis = g => g
         .attr("transform", `translate(${margin.left},0)`)
         .call(d3.axisLeft(y).tickSizeInner(-width).tickPadding(5))
         .call(g => g.select(".domain").remove())

      var yTitle = g => g.append("text")
          .attr("font-family", "sans-serif")
          .attr("font-size", 10)
          .attr("y", 10)
          .attr("x", 2)
          .text("Miles")

      svg.append("g")
         .call(xAxis);

      svg.append("g")
         .call(yAxis)
         .selectAll(".tick line")
         .style("opacity", 0.2);

      svg.call(yTitle);

      svg.append("g")
        .attr("fill", "#333")
        .selectAll("rect")
        .data(data)
        .join("rect")
        .attr("x", d => x(d.date))
        .attr("y", d => y(d.value))
        .attr("height", d => y(0) - y(d.value))
        .attr("width", x.bandwidth())

      svg.append("g")
        .attr("fill", "#333")
        .attr("text-anchor", "middle")
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
      .selectAll("text")
      .data(data)
      .join("text")
        .attr("x", d => x(d.date) + (x.bandwidth() / 2))
        .attr("y", d => y(d.value) - 5)
        .text(d => d.value);

      return svg.node();
    }

  }
})
