document.addEventListener('turbo:load', function() {
  if (document.querySelector(".my-geolocations-form")) {
    var newGeolocationForm = document.querySelector("#new_geolocation");
    var getCurrentPositionButton = newGeolocationForm.querySelector(".js-geolocation-get-current-position");

    getCurrentPositionButton.addEventListener("click", function() {
      getCurrentPosition();
    }, false);

    function getCurrentPosition() {
      var options = {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 5000
      };

      function setInputValues(coordinates) {
        const latitudeInput = newGeolocationForm.querySelector("#geolocation_latitude");
        const longitudeInput = newGeolocationForm.querySelector("#geolocation_longitude");
        const altitudeInput = newGeolocationForm.querySelector("#geolocation_altitude");
        const accuracyInput = newGeolocationForm.querySelector("#geolocation_accuracy");
        const altitudeAccuracyInput = newGeolocationForm.querySelector("#geolocation_altitude_accuracy");

        latitudeInput.value = coordinates.latitude;
        longitudeInput.value = coordinates.longitude;
        altitudeInput.value = coordinates.altitude;
        accuracyInput.value = coordinates.accuracy;
        altitudeAccuracyInput.value = coordinates.altitudeAccuracy;
      }

      function success(position) {
        var coordinates = position.coords;

        setInputValues(coordinates);
      }

      function error(error) {
        console.warn(err.message);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    };
  }
});
