document.addEventListener('turbo:load', function() {
  if (document.querySelector(".photos-index, .photos-show")) {
    const blackAndWhiteButton = document.body.querySelector(".js-black-and-white");
    const colorButton = document.body.querySelector(".js-color");

    setPhotoBlackAndWhiteSetting();
    setPhotoColorButtonClasses();

    blackAndWhiteButton.addEventListener("click", function() {
      updatePhotoBlackAndWhiteSetting(true);
    }, false);

    colorButton.addEventListener("click", function() {
      updatePhotoBlackAndWhiteSetting(false);
    }, false);

    function updatePhotoBlackAndWhiteSetting(value) {
      sessionStorage.setItem('showPhotosInBlackAndWhite', value);
      setPhotoBlackAndWhiteSetting();
      setPhotoColorButtonClasses();
    }

    function setPhotoBlackAndWhiteSetting() {
      let showPhotosInBlackAndWhite = sessionStorage.getItem('showPhotosInBlackAndWhite') === 'true';
      document.body.classList.toggle("black-and-white", showPhotosInBlackAndWhite);
    }

    function setPhotoColorButtonClasses() {
      let showPhotosInBlackAndWhite = sessionStorage.getItem('showPhotosInBlackAndWhite') === 'true';
      blackAndWhiteButton.classList.toggle("selected", showPhotosInBlackAndWhite)
      colorButton.classList.toggle("selected", !showPhotosInBlackAndWhite);
    }
  }
})
