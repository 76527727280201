document.addEventListener('turbo:load', function() {
  if (document.querySelector('.navigation-bars-user')) {
    var component = document.querySelector('.navigation-bars-user');
    var searchWrapper = component.querySelector('.search');
    var shortcutInput = component.querySelector('#shortcuts_options');
    var shortcutsWrapperElement = document.querySelector('.shortcuts-wrapper');
    var shortcutsWrapperBackgroundOverlay = shortcutsWrapperElement.querySelector('.background-overlay');
    var cancelButton = searchWrapper.querySelector('.cancel');
    var shortcutQuery = null;

    function getShortcuts(query) {
      var xhr = new XMLHttpRequest();
      var params = query ? 'test=' + query : null;
      xhr.open('GET', ['/my/shortcuts', params].join('?'));

      xhr.onload = function () {
        responseData = JSON.parse(this.response);
        renderResults(responseData);
      };

      xhr.send();
    }

    shortcutInput.addEventListener("focus", function(e) {
      let query = e.target.value;
      getShortcuts(query);
    });

    shortcutInput.addEventListener('input', function(e) {
      let query = e.target.value;
      getShortcuts(query);
    })

    shortcutsWrapperBackgroundOverlay.addEventListener('click', function() {
      document.body.classList.remove('shortcuts-open');
      shortcutInput.value = null;
    });

    cancelButton.addEventListener('click', function() {
      document.body.classList.remove('shortcuts-open');
      shortcutInput.value = null;
    });

    function renderResults(data) {
      document.body.classList.add('shortcuts-open');

      let resultsWrapperElement = shortcutsWrapperElement.querySelector('.results-wrapper');
      resultsWrapperElement.innerHTML = '';

      data.forEach(function(s) {
        var shortcut = document.createElement('a');
        shortcut.classList.add('list-group-item', 'list-group-item-action');
        shortcut.href = s.path;
        shortcut.textContent = s.label;
        resultsWrapperElement.insertAdjacentElement('beforeend', shortcut);
      });
    }
  }
});
